<template>
  <v-container style="max-width: 600px">
    <img src="https://storage.googleapis.com/tina-public/rezerwacja-graph.png" style="max-width: 600px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row v-if="step === 1">
        <v-col cols="12">
          <h2>Wybierz termin rezerwacji</h2>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="startDateDialog"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="startDate"
                v-model="startDate"
                label="Data przyjazdu"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="startDateRules.concat(compareDates)"
                @change="checkPrice"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              locale="pl-PL"
              no-title
              scrollable
              first-day-of-week = "1"
              @input="checkPrice"
              :allowed-dates="allowedStartDate"
            ></v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
              ref="startTimeDialog"
              v-model="startTimeDialog"
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  id="startTime"
                  v-model="startTime"
                  label="Godzina przyjazdu"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :rules="startTimeRules.concat(compareHours)"
                  @change="checkPrice"
              ></v-text-field>
            </template>
            <v-time-picker
                v-if="startTimeDialog"
                v-model="startTime"
                full-width
                format="24hr"
                @click:hour="checkPrice"
            ></v-time-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="endDateDialog"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="endDate"
                v-model="endDate"
                label="Data wyjazdu"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="endDateRules.concat(compareDates)"
                @change="checkPrice"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              locale="pl-PL"
              no-title
              scrollable
              first-day-of-week = "1"
              :allowed-dates="allowedEndDate"
              @input="checkPrice"
            ></v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
              ref="endTimeDialog"
              v-model="endTimeDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  id="endTime"
                  v-model="endTime"
                  label="Godzina wyjazdu"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :rules="endTimeRules.concat(compareHours)"
                  @change="checkPrice"
              ></v-text-field>
            </template>
            <v-time-picker
                v-if="endTimeDialog"
                v-model="endTime"
                full-width
                format="24hr"
                @click:hour="checkPrice"
            ></v-time-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-text-field prepend-icon="mdi-credit-card-outline" id="price" v-model="price" readonly prefix="CAŁKOWITY KOSZT:"></v-text-field>
        </v-col>
        <v-col id="reservationButtonCol" cols="6" align="right">
          <v-btn width="134px" height="47px" @click="changeStep(2)" color="orange">Rezerwuj</v-btn>
        </v-col>
      </v-row>
      <v-row ref="step2" v-if="step === 2">
        <v-col cols="12">
          <h2>Podaj dane do rezerwacji</h2>
        </v-col>
        <v-col cols="6">
          <v-text-field id="firstName" label="Imię" v-model="firstName" outlined :rules="validateStep(firstNameRules, 2)"  ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field id="lastName" label="Nazwisko" v-model="lastName" outlined :rules="validateStep(lastNameRules, 2)" ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field id="email" label="Email" v-model="email" outlined :rules="validateStep(emailRules,2)"></v-text-field>
        </v-col>
		<v-col cols="6">
          <v-text-field id="emailRepeat" label="Powtórz Email" v-model="emailRepeat" outlined :rules="validateStep(emailRepeatRules.concat(compareEmails),2)"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field id="phone" label="Telefon" v-model="phone" outlined :rules="validateStep(phoneRules, 2)" validate-on-blur></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field id="plateNumber" label="Numer rejestracyjny pojazdu" v-model="plateNumber" outlined :rules="validateStep(plateNumberRules,2)" validate-on-blur></v-text-field>
        </v-col>
        <v-col cols="12" id="gdprCol">
          <v-checkbox
              id="invoice"
              label="Chcę otrzymać fakturę VAT (dane w następnym kroku)" v-model="invoice"></v-checkbox>
          <v-checkbox
              v-model="gdprAccepted"
              label="Niniejszym wyrażam zgodę na przetwarzanie przez Real-Construct Sp. z o.o. moich danych osobowych udostępnionych w związku ze świadczeniem usługi rezerwacji on-line i korzystania z parkingu, którego właścicielem jest Real-Construct Sp. z o.o."
              :rules="validateStep([v => !!v || 'Zgoda RODO jest wymagana'],2)"></v-checkbox>
          <v-checkbox
              v-model="regulationsAccepted"
              :rules="validateStep([v => !!v || 'Zgody są wymagane'],2)">
              <template v-slot:label>
                <div>
                  Niniejszym informuję, iż zapoznałem się oraz akceptuję postanowienia 
                  <a target="_blank" href="http://parkingtina.pl/regulaminy/RC_regulamin-platnosci-on-line_Parking-Tina_(10.10.2021).pdf" @click.stop>Polityki prywatności, Regulaminu parkingu oraz Regulaminu rezerwacji on-line</a>.
                </div>
              </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-btn width="176px" height="47px" @click="changeStep(1)" color="orange">Wstecz</v-btn>
        </v-col>
        <v-col cols="6" align="right" v-if="invoice === false">
          <v-btn width="176px" height="47px" @click="register" color="orange">Kupuję i płacę</v-btn>
        </v-col>
		<v-col cols="6" align="right" v-if="invoice === true">
          <v-btn width="176px" height="47px" @click="changeStep(3)" color="orange">Dane do faktury</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="step === 3">
        <v-col cols="12">
          <h2>Podaj dane do faktury</h2>
        </v-col>
        <v-col cols="6">
              <v-text-field id="nip" label="Numer NIP" v-model="nip" outlined :rules="nipRules"></v-text-field>
        </v-col>
		<v-col cols="6">
              <v-text-field id="companyName" label="Nazwa firmy" v-model="companyName" outlined :rules="companyNameRules"></v-text-field>
        </v-col>
		<v-col cols="6">
              <v-text-field id="companyStreet" label="Ulica" v-model="companyStreet" outlined :rules="companyStreetRules"></v-text-field>
        </v-col>
		<v-col cols="6">
              <v-text-field id="companyBuilding" label="Numer domu/mieszkania" v-model="companyBuilding" outlined :rules="companyBuildingRules"></v-text-field>
        </v-col>
		<v-col cols="6">
              <v-text-field id="companyPostalCode" label="Kod Pocztowy" v-model="companyPostalCode" outlined :rules="companyPostalCodeRules"></v-text-field>
        </v-col>
		<v-col cols="6">
              <v-text-field id="companyCity" label="Miasto" v-model="companyCity" outlined :rules="companyCityRules"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-btn width="176px" height="47px" @click="changeStep(2)" color="orange">Wstecz</v-btn>
        </v-col>
        <v-col cols="6" align="right">
          <v-btn width="176px" height="47px" @click="register" color="orange">Kupuję i płacę</v-btn>
        </v-col>
      </v-row>
	</v-form>
  </v-container>
</template>

<script>
import axios from "axios";

const calculateHours = () => {
  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(i + ":00");
    hours.push(i + ":15");
    hours.push(i + ":30");
    hours.push(i + ":45");
  }
  return hours;
};

const getTimeZoneOffset = (date, time) => {
    let timezone_transitions = document.cookie
      .split('; ')
      .find((row) => row.startsWith('timezone_transitions='))
      ?.split('=')[1].split(',');
    
	if (timezone_transitions === undefined) timezone_transitions = '2022-07-28T11:05:24+0000|1659006324|7200,2022-10-30T01:00:00+0000|1667091600|3600,2023-03-26T01:00:00+0000|1679792400|7200,2023-10-29T01:00:00+0000|1698541200|3600,2024-03-31T01:00:00+0000|1711846800|7200,2024-10-27T01:00:00+0000|1729990800|3600,2025-03-30T01:00:00+0000|1743296400|7200,2025-10-26T01:00:00+0000|1761440400|3600,2026-03-29T01:00:00+0000|1774746000|7200,2026-10-25T01:00:00+0000|1792890000|3600,2027-03-28T01:00:00+0000|1806195600|7200'.split(',');
	//console.log(timezone_transitions);
	
	let full_date = new Date(date + 'T' + time + '+0000');
	let prev_transition = timezone_transitions[0];
	
	for (const item of timezone_transitions) {
		let item_arr = item.split('|');
		let date_check = new Date(item_arr[0]);
		
		if (date_check.getTime() + parseInt(item_arr[2], 10)*1000 > full_date.getTime()) {
			break;
		} else {
			prev_transition = item;
		}
	}
	
	let transition_arr = prev_transition.split('|');
	return Math.round(transition_arr[2] / 3600);
}

const newDate = (daysOffset) => {
  let date = new Date();
  date.setDate(date.getDate() + daysOffset);
  return date;
};

const getGcpFunctionHost = () => {
  //const hostname = window.location.hostname.split('.')[0];
  //return `https://europe-central2-${hostname}.cloudfunctions.net`;
  return `https://europe-central2-tina-parking-app.cloudfunctions.net`;
  //return `https://europe-central2-tina-parking-dev.cloudfunctions.net`;
};

export default {
  name: "Reservation",

  data: () => ({
    items: calculateHours(),
    startDate: newDate(1).toISOString().substr(0, 10),
    startTime: "10:00",
    startDateDialog: false,
    startTimeDialog: false,
    endDate: newDate(8).toISOString().substr(0, 10),
    endTime: "10:00",
    endDateDialog: false,
    endTimeDialog: false,
    price: "---",
    firstName: "",
    firstNameRules: [
      v => !!v || 'Imię jest wymagane'
    ],
    lastName: "",
    lastNameRules: [
      v => !!v || 'Nazwisko jest wymagane',
    ],
    email: "",
    emailRules: [
      v => !!v || 'Email jest wymagany',
      v => /.+@.+\..+/.test(v) || 'Email musi być poprawny',
    ],
    emailRepeat: "",
    emailRepeatRules: [
      v => !!v || 'Powtórz Email jest wymagany',
    ],
    phone: "",
    phoneRules: [
      v => !!v || 'Telefon jest wymagany'
    ],
	companyName: "",
	companyNameRules: [
		v => !!v || 'Nazwa firmy jest wymagana'
	],
	companyBuilding: "",
	companyBuildingRules: [
		v => !!v || 'Nr domu/budynku jest wymagany'
	],
	companyCity: "",
	companyCityRules: [
		v => !!v || 'Miasto jest wymagane',
	],
	companyPostalCode: "",
	companyPostalCodeRules: [
		v => !!v || 'Kod Pocztowy jest wymagany',
		v => /^[0-9]{2,2}-[0-9]{3,3}$/.test(v) || 'Kod pocztowy musi być w formacie 12-345',
	],
	companyStreet: "",
	companyStreetRules: [
		v => !!v || 'Ulica jest wymagana'
	],
	companyAddress: "",
	companyAddressRules: [
		v => !!v || 'Adres firmy jest wymagany'
	],
	nip: "",
	nipRules: [
		v => !!v || 'NIP jest wymagany',
        v => /^[0-9]{10,10}$/.test(v) || 'Numer nip musi zawierać dokładnie 10 cyfr (bez spacji, myślników itd)',
	],
    plateNumber: "",
    plateNumberRules: [
      v => !!v || 'Numer rejestracyjny pojazdu jest wymagany',
      v => v.length < 9 || 'Numer rejestracyjny nie może przekraczać 8 znaków',
      v => v.length > 2 || 'Numer rejestracyjny musi mieć co najmniej 3 znaki',
      v => /^[a-zA-Z0-9]{3,8}$/.test(v) || 'Numer rejestracyjny może zawierać tylko litery i cyfry (bez spacji, przecinków itd)',
    ],
    startDateRules: [
      v => !!v || 'Data jest wymagana',
    ],
    endDateRules: [
      v => !!v || 'Data jest wymagana',
    ],
    startTimeRules: [
      v => !!v || 'Godzina jest wymagana',
    ],
    endTimeRules: [
      v => !!v || 'Godzina jest wymagana',
    ],
    step: 1,
	invoice: false,
    gdprAccepted: false,
    regulationsAccepted: false,
    valid: true,
  }),
  methods: {
	validateStep(property, step){
      if (typeof property === 'string' || property instanceof String) {
        property = this[property];
      }
      if (this.step == step) return property;
      return true;
	},
  
	firstNameErrorHandler() {
      return true;
	},
    firstNameErrorMessagesHandler(){
      return [this.step + ' Cośtam'];	
	},
    compareEmails(val) {
      return val == this.email || "Email musi być taki sam";
    },
    allowedStartDate(val){
      return val >= newDate(1).toISOString().substr(0, 10);
    },
	allowedEndDate(val){
      return val >= this.startDate;
    },
    compareDates() {
      return this.endDate >= this.startDate || "Data wyjazdu musi być nie wcześniej niż data przyjazdu";
    }, 
    compareHours() {
      if (this.endDate == this.startDate) return Date.parse('01/01/2011 ' + this.endTime) > Date.parse('01/01/2011 ' + this.startTime) || "Godzina wyjazdu musi być po godzinie przyjazdu w tym samym dniu";
      return true;
    }, 
    checkPrice() {
      this.startTimeDialog = false;
      this.endTimeDialog = false;
      this.startDateDialog = false;
      this.endDateDialog = false;
      if (this.$refs.form) {
        const registrationValid = this.$refs.form.validate();
        if (registrationValid !== true) {
          return;
        }
      }
      const startTimeZoneOffset = getTimeZoneOffset(this.startDate, this.startTime);
      const endTimeZoneOffset = getTimeZoneOffset(this.endDate, this.endTime);
	
      axios
        .get(
          getGcpFunctionHost() + "/calculate-price?startDate=" +
            this.startDate +
            "&startTime=" +
            this.startTime +
            "&startTimeZoneOffset=" +
            startTimeZoneOffset +
            "&endDate=" +
            this.endDate +
            "&endTime=" +
            this.endTime +
            "&endTimeZoneOffset=" +
            endTimeZoneOffset
        )
        .then((response) => {
          this.price = response.data + " zł";
        });
    },
    changeStep(stepNumber) {
      if (stepNumber < this.step) {
        this.step = stepNumber;
		this.$refs.form.resetValidation();
		window.scrollTo({top: document.querySelector("#main").getBoundingClientRect().top + window.scrollY, behavior: 'smooth'});
        return;
      }
      const validationValid = this.$refs.form.validate();
      if (validationValid) {
        this.step = stepNumber;
		this.$refs.form.resetValidation();
		window.scrollTo({top: document.querySelector("#main").getBoundingClientRect().top + window.scrollY, behavior: 'smooth'});
      }
    },
    register() {
      const registrationValid = this.$refs.form.validate()
      if (registrationValid !== true) {
        console.error('Validation failed');
        return;
      }
      
      const startTimeZoneOffset = getTimeZoneOffset(this.startDate, this.startTime);
      const endTimeZoneOffset = getTimeZoneOffset(this.endDate, this.endTime);

      axios
        .get(
          getGcpFunctionHost() + "/reserve-parking?firstName=" +
            encodeURIComponent(this.firstName) +
            "&lastName=" +
            encodeURIComponent(this.lastName) +
            "&email=" +
            encodeURIComponent(this.email) +
            "&phone=" +
            encodeURIComponent(this.phone) +
            "&plateNumber=" +
            encodeURIComponent(this.plateNumber) +
            "&price=" +
            encodeURIComponent(this.price) +
            "&startDate=" +
            encodeURIComponent(this.startDate) +
            "&startTime=" +
            encodeURIComponent(this.startTime) +
            "&startTimeZoneOffset=" +
            encodeURIComponent(startTimeZoneOffset) +
            "&endDate=" +
            encodeURIComponent(this.endDate) +
            "&endTime=" +
            encodeURIComponent(this.endTime) +
            "&endTimeZoneOffset=" +
            encodeURIComponent(endTimeZoneOffset) +
            "&invoice=" +
            encodeURIComponent(this.invoice?'1':'0') +
            "&companyName=" +
            encodeURIComponent(this.companyName) +
            "&companyStreet=" +
            encodeURIComponent(this.companyStreet) +
            "&companyBuilding=" +
            encodeURIComponent(this.companyBuilding) +
            "&companyPostalCode=" +
            encodeURIComponent(this.companyPostalCode) +
            "&companyCity=" +
            encodeURIComponent(this.companyCity) +
            "&nip=" +
            encodeURIComponent(this.nip)
        )
        .then(response => {
          window.location.href = response.data;
        })
        .catch(err => {
          console.error("Invalid response", err);
        });
    }
  },
  beforeMount() {
    this.checkPrice();
  }
};
</script>
<style>

#startDate, #startTime, #endDate, #endTime, #price, #firstName, #lastName, #email, #emailRepeat, #phone, #plateNumber {
  box-shadow: none;
  border: none;
}

#reservationButtonCol {
  padding-left: 45px;
}

#gdprCol {
  padding-top: 0;
}

label[for=startDate], label[for=startTime], label[for=endDate], label[for=endTime], label[for=price], label[for=firstName], label[for=lastName], label[for=email], label[for=emailRepeat], label[for=phone], label[for=plateNumber] {
  padding: 0;
}

h2 { font-weight:normal; }

</style>